import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';

import { changeToken, useDispatch } from 'store';

import { http } from 'services';

import { LoginForm, loginSchema } from '../schema';

interface LoginProps {
  defaultValues?: Partial<LoginForm>;
  children: (props: UseFormReturn<LoginForm>) => React.ReactNode;
}

const Login: React.FC<LoginProps> = ({ children, defaultValues = {} }) => {
  const dispatch = useDispatch();
  const form = useForm<LoginForm>({ resolver: zodResolver(loginSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<void, any, LoginForm>({
    mutationFn: async values => {
      const { data } = await http.request.post<{ token: string }>('/auth/login', values);
      const accessToken = data.token;

      dispatch(changeToken({ accessToken }));
    }
  });

  const onSubmit = async (data: LoginForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default Login;
