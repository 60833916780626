import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import BaseInput, { InputProps as BaseInputProps } from 'antd/lib/input';

interface InputProps extends BaseInputProps {
  error?: string;
  control: Control<any>;
  name: string;
}

const Input: FC<InputProps> = ({ error, name, control, ...args }: InputProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <div className="flex flex-col gap-1">
        <label htmlFor={name}>{args.placeholder}</label>
        <BaseInput.Password {...args} name={name} value={field.value} onChange={field.onChange} />
        {error && <div className="text-sm !text-red-600">{error}</div>}
      </div>
    )}
  />
);

export default Input;
