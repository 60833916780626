import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import pick from 'lodash/pick';
import { StringParam, useQueryParam } from 'use-query-params';

import { Form } from 'modules/purchases';
import { usePayout } from 'modules/purchases/hooks';

import { DatePicker, Input } from 'components';

const Update: React.FC = () => {
  const { purchaseId } = useParams();
  const [payoutId, setPayoutId] = useQueryParam('payoutId', StringParam);
  const { item, isLoading } = usePayout({ purchaseId: purchaseId!, payoutId: payoutId! });

  if (isLoading)
    return (
      <div className="p-2">
        <Skeleton active loading />
        <Skeleton active loading />
        <Skeleton active loading />
      </div>
    );

  return (
    <div className="bg-white w-full rounded-lg shadow">
      <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
        <h1 className="m-0 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Mahsulotni tahrirlash</h1>
        <Form.UpdatePayout onSuccess={() => setPayoutId(undefined)} defaultValues={pick(item!, ['amount', 'description', 'createdAt'])}>
          {({ control, formState: { errors, isSubmitting } }) => (
            <div className="flex flex-col gap-3">
              <Input size="large" type="number" control={control} name="amount" placeholder="Miqdori" error={errors.amount?.message} />
              <Input size="large" control={control} name="description" placeholder="Izoh" error={errors.description?.message} />
              <DatePicker control={control} name="createdAt" placeholder="To'lov sanasi" error={errors.createdAt?.message} />
              <Button htmlType="submit" className="text-dark mx-auto" loading={isSubmitting} size="large">
                Saqlash
              </Button>
            </div>
          )}
        </Form.UpdatePayout>
      </div>
    </div>
  );
};

export default Update;
