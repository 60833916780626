import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig } from 'redux-persist';
import createTransform from 'redux-persist/es/createTransform';
import reducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { User } from 'schema';

export interface IStore {
  user: User | null;
  isFetched: boolean;
  accessToken?: string;
}

const initialState: IStore = { user: null, isFetched: true, accessToken: undefined };

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(store, { payload }: PayloadAction<Pick<IStore, 'user'>>) {
      store.user = payload.user;
      store.isFetched = true;
    },
    logout(store) {
      store.user = null;
      store.isFetched = true;
      store.accessToken = undefined;
    },
    changeToken(store, { payload }: PayloadAction<Pick<IStore, 'accessToken'>>) {
      store.accessToken = payload.accessToken;
    }
  }
});

const isFetchedTransform = createTransform<boolean, boolean, IStore, any>(
  state => state,
  (isFetched, key, stored) => {
    const accessToken = JSON.parse(stored.accessToken);

    return !accessToken;
  },
  { whitelist: ['isFetched'] }
);

const config: PersistConfig<IStore> = {
  key: 'auth',
  storage,
  whitelist: ['accessToken', 'isFetched'],
  transforms: [isFetchedTransform]
};

export default reducer<IStore>(config, slice.reducer);

export const { login, logout, changeToken } = slice.actions;
