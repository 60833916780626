import React from 'react';
import { Button } from 'antd';
import { StringParam, useQueryParam } from 'use-query-params';

import { Form } from 'modules/purchases';

import { Input } from 'components';

const Add: React.FC = () => {
  const [productId, setProductId] = useQueryParam('productId', StringParam);

  if (productId !== 'new') return null;

  return (
    <div className="bg-white w-full rounded-lg shadow">
      <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
        <h1 className="m-0 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Mahsulot qo'shish</h1>
        <Form.AddProduct onSuccess={() => setProductId(undefined)}>
          {({ control, formState: { errors, isSubmitting } }) => (
            <div className="flex flex-col gap-2">
              <Input size="large" control={control} name="name" placeholder="Nomi" error={errors.name?.message} />
              <Input size="large" control={control} type="number" name="box" placeholder="Qutida" error={errors?.box?.message} />
              <Input size="large" control={control} type="number" name="quantity" placeholder="Miqdor" error={errors.quantity?.message} />
              <Input size="large" control={control} type="number" name="price" placeholder="Narx" error={errors.price?.message} />
              <Input size="large" control={control} name="code" placeholder="Shtrix kod" error={errors.code?.message} />
              <Button htmlType="submit" className="text-dark mx-auto" loading={isSubmitting} size="large">
                Yaratish
              </Button>
            </div>
          )}
        </Form.AddProduct>
      </div>
    </div>
  );
};

export default Add;
