import React from 'react';
import { useParams } from 'react-router-dom';
import { Collapse, Skeleton, Tag } from 'antd';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { date } from 'utils';

import { useShop } from 'modules/shops';

const Single: React.FC = () => {
  const { shopId = '' } = useParams();
  const { shop, isLoading } = useShop({ shopId });
  const [{ collapsed = [] }, setParams] = useQueryParams({
    collapsed: ArrayParam,
    productId: StringParam,
    payoutId: StringParam
  });

  if (isLoading)
    return (
      <>
        <Skeleton className="p-2" loading active />
        <Skeleton className="p-2" loading active />
        <Skeleton className="p-2" loading active />
      </>
    );

  return (
    <>
      <div className="p-2">
        <div className="flex justify-between py-2">
          <div className="flex items-center gap-2">
            <span className="text-xl font-bold">Do'kon:</span>
            <Tag color="cyan">{shop?.title}</Tag>
            <Tag color="blue">{date(shop?.createdAt!)}</Tag>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Collapse
            className="bg-white-100"
            activeKey={collapsed as string[]}
            onChange={collapsed => setParams({ collapsed: collapsed as string[] })}
            items={[
              {
                key: 'purchases',
                label: 'Purchases',
                children: <h1>Purchases</h1>
              }
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default Single;
