import { useQueryClient } from '@tanstack/react-query';
import { PURCHASE } from 'keys';
import { Meta, Purchase } from 'schema';

import { useQuery } from 'hooks';

import { http } from 'services';

type IQuery = {
  purchases: Purchase[];
  meta: Meta;
};

export const usePurchases = (params?: Omit<Meta, 'total'>) => {
  const client = useQueryClient();

  const initialData: IQuery = { purchases: [], meta: { limit: 0, total: 0, page: 0 } };

  const { data = initialData, ...args } = useQuery<IQuery, string, IQuery>({
    queryKey: PURCHASE.list(params).queryKey,
    queryFn: async () => {
      const { data } = await http.request.get<IQuery>('/purchases', { params });

      for (const purchase of data.purchases) {
        client.setQueryData(PURCHASE.single({ purchaseId: purchase?._id! }).queryKey, { purchase });
      }

      return data;
    },
    staleTime: 60000
  });

  return { ...data, ...args };
};
