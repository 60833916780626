import { Product } from 'schema';

import { useQuery } from 'hooks';

import { http } from 'services';

interface IQuery {
  item: Product;
}

interface Params {
  purchaseId: string;
  productId: string;
}

export const useProduct = ({ purchaseId, productId }: Params) => {
  const { data, ...args } = useQuery<IQuery, string, IQuery>({
    queryKey: ['PURCHASE', 'PRODUCT', purchaseId, productId],
    queryFn: async () => {
      const { data: item } = await http.request.get<Product>(`/purchases/${purchaseId}/product/${productId}`);

      return { item };
    },
    enabled: !!(purchaseId && productId),
    staleTime: 60000
  });

  return { ...data, ...args };
};
