import z from 'zod';

export const loginSchema = z.object({
  phone: z.string().min(3).trim(),
  password: z.string().min(4).trim()
});

export const registerSchema = z
  .object({
    firstName: z.string().min(3).trim(),
    lastName: z.string().min(3).trim(),
    phone: z.string().min(3).trim(),
    password: z.string().min(4).trim(),
    confirm: z.string().min(4).trim()
  })
  .refine(data => data.password === data.confirm, {
    message: "Passwords don't match",
    path: ['confirm']
  });

export type LoginForm = z.infer<typeof loginSchema>;
export type RegisterForm = z.infer<typeof registerSchema>;
