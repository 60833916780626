import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Tag } from 'antd';
import { StringParam, useQueryParam } from 'use-query-params';

import { Form } from 'modules/purchases';
import { useShops } from 'modules/shops';

import { DatePicker, Select } from 'components';

const Add: React.FC = () => {
  const [purchaseId, setPurchaseId] = useQueryParam('purchaseId', StringParam);
  const { shops, isLoading } = useShops({ page: 1, limit: 1000, enabled: purchaseId === 'new' });
  const navigate = useNavigate();

  if (purchaseId !== 'new') return null;

  return (
    <Modal onCancel={() => setPurchaseId(undefined)} open={purchaseId === 'new'} centered>
      <div className="bg-white w-full rounded-lg shadow">
        <div className="flex flex-col gap-2 p-6">
          <h1 className="m-0 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Sotuv Yaratish</h1>
          <Form.AddPurchase onSuccess={purchase => navigate(`/app/purchases/${purchase._id}`)}>
            {({ control, formState: { errors, isSubmitting } }) => (
              <div className="flex flex-col gap-3">
                <Select
                  allowClear
                  searchableKeys={['title', 'phone']}
                  className="w-full"
                  size="large"
                  control={control}
                  options={shops.map(shop => ({
                    label: (
                      <div className="flex items-center gap-2">
                        <span>{shop.title}</span>
                        <Tag>{shop.phone}</Tag>
                      </div>
                    ),
                    value: shop._id,
                    record: shop
                  }))}
                  loading={isLoading}
                  placeholder="Do'kon"
                  error={errors?.shop?.message}
                  name="shop"
                />

                <DatePicker placeholder="Sotuv sanasi" control={control} disabled={isLoading} name="createdAt" />
                <Button htmlType="submit" className="text-dark mx-auto w-max self-end" loading={isSubmitting || isLoading}>
                  Yaratish
                </Button>
              </div>
            )}
          </Form.AddPurchase>
        </div>
      </div>
    </Modal>
  );
};

export default Add;
