import React from 'react';
import BaseIcon, { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/es/components/Icon';
import { cx } from 'utils';

import * as List from './list';

import cls from './icon.module.css';

export type IconName = keyof typeof List;

export interface IconProps extends Omit<IconComponentProps, 'component'> {
  name: IconName;
}

const Icon: React.FC<IconProps> = ({ name, className = '', ...props }) => {
  const IconItem: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | React.ForwardRefExoticComponent<CustomIconComponentProps> =
    List[name];

  if (!IconItem) {
    console.log('Icon component not found');

    return null;
  }

  return <BaseIcon ref={{ current: null } as any} component={IconItem as any} className={cx(cls.wrapper, className || 'h-4 w-4')} {...props} />;
};

export default Icon;
