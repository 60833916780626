import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Payout } from 'schema';

import { http } from 'services';

import { PayoutForm, payoutSchema } from '../schema';

interface AddPayoutProps {
  defaultValues?: Partial<PayoutForm>;
  children: (props: UseFormReturn<PayoutForm>) => React.ReactNode;
  onSuccess?: (data: Payout) => void;
}

const AddPayout: React.FC<AddPayoutProps> = ({ children, defaultValues = {}, onSuccess }) => {
  const client = useQueryClient();
  const { purchaseId } = useParams();
  const form = useForm<PayoutForm>({ resolver: zodResolver(payoutSchema as any), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Payout, any, PayoutForm>({
    mutationFn: async values => {
      const { data: item } = await http.request.post<Payout>(`/purchases/${purchaseId}/payout`, values);

      return item;
    },
    onSuccess: async payout => {
      client.invalidateQueries({ queryKey: ['PURCHASE', 'LIST'] });
      client.invalidateQueries({ queryKey: ['PURCHASE', purchaseId] });

      message.success('Mahsulot muvaffaqiyatli yaratildi');
      form.reset();
      onSuccess!(payout);
    }
  });

  const onSubmit = async (data: PayoutForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default AddPayout;
