import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { message } from 'antd';

import { http } from 'services';

import { RegisterForm, registerSchema } from '../schema';

interface RegisterProps {
  defaultValues?: Partial<RegisterForm>;
  children: (props: UseFormReturn<RegisterForm>) => React.ReactNode;
}

const Register: React.FC<RegisterProps> = ({ children, defaultValues = {} }) => {
  const navigate = useNavigate();
  const form = useForm<RegisterForm>({ resolver: zodResolver(registerSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<void, any, RegisterForm>({
    mutationFn: async ({ confirm, ...values }) => {
      await http.request.post('/auth/register', values);

      message.success('Successfully registered');
      navigate('/auth/login');
    }
  });

  const onSubmit = async (data: RegisterForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default Register;
