import { Control, Controller } from 'react-hook-form';
import { DatePicker as BaseDatePicker } from 'antd';
import dayjs from 'dayjs';

interface DatePickerProps {
  error?: string;
  control: Control<any>;
  name: string;
  disabled?: boolean;
  placeholder?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({ error, name, control, disabled = false, placeholder }: DatePickerProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <div className="flex flex-col gap-1">
        <label htmlFor={name}>{placeholder}</label>
        <BaseDatePicker
          name={name}
          size="large"
          showToday={false}
          value={field.value ? dayjs(field.value) : undefined}
          onChange={value => field.onChange(value?.format())}
          allowClear
          disabledDate={current => current.isAfter()}
          disabled={disabled}
          placeholder={placeholder}
          id={name}
        />
        {error && <div className="text-sm !text-red-600">{error}</div>}
      </div>
    )}
  />
);

export default DatePicker;
