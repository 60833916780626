import React from 'react';
import { Button } from 'antd';

import { Form, useShop } from 'modules/shops';

import { Icon, Input, Loader, PhoneInput } from 'components';

interface UpdateProps {
  shopId: string;
}

const Update: React.FC<UpdateProps> = ({ shopId }) => {
  const { shop, isLoading } = useShop({ shopId });

  if (isLoading) return <Loader />;

  return (
    <div className="bg-white w-full rounded-lg shadow">
      <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Do'kon Tahrirlash</h1>
        <Form.Update defaultValues={shop}>
          {({ control, formState: { errors, isSubmitting } }) => (
            <div className="flex flex-col gap-3">
              <div className="flex flex-col justify-center gap-3">
                <Input control={control} size="large" prefix={<Icon name="shop" />} placeholder="Nom" error={errors.title?.message} name="title" />
                <Input control={control} size="large" prefix={<Icon name="location" />} placeholder="Manzil" error={errors.location?.message} name="location" />
                <PhoneInput
                  control={control}
                  size="large"
                  prefix={<Icon name="phone" />}
                  placeholder="Telefon raqam"
                  error={errors.phone?.message}
                  name="phone"
                />
                <Input
                  minLength={4}
                  control={control}
                  size="large"
                  prefix={<Icon name="target" />}
                  placeholder="Raqam"
                  error={errors.number?.message}
                  name="number"
                  min={4}
                />
              </div>
              <Button htmlType="submit" className="text-dark mx-auto" loading={isSubmitting}>
                Saqlash
              </Button>
            </div>
          )}
        </Form.Update>
      </div>
    </div>
  );
};

export default Update;
