import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Avatar, Breadcrumb, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/breadcrumb/Breadcrumb';

import { useAuth } from 'modules/auth/hooks';

import { Icon } from 'components';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const { methods, user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { shopId } = useParams();

  const items: Array<ItemType & { visible: boolean }> = [
    { key: 'home', title: 'jonatma.uz', onClick: () => navigate('/'), visible: pathname.includes('/shops') },
    { key: 'shops', title: 'shops', onClick: () => navigate('/shops'), visible: pathname.includes(`/shops/${shopId}`) },
    { key: 'shops-single', title: shopId, onClick: () => navigate(`/shops/${shopId}`), visible: pathname.includes(`/shops/${shopId}/purchases`) }
  ];

  return (
    <div className="flex flex-col justify-between">
      <div className="fixed top-0 z-[5] box-border flex w-full items-center justify-between bg-gray-100 p-2">
        <Breadcrumb items={items.filter(item => item.visible)} />
        <Dropdown
          menu={{
            items: [
              {
                key: 'profile',
                label: 'Profile',
                icon: <Icon className="h-4 w-4" name="profile" />
              },
              {
                key: 'logout',
                label: 'Chiqish',
                icon: <Icon className="h-4 w-4" name="logout" />,
                onClick: methods.logout
              }
            ]
          }}
          className="cursor-pointer"
        >
          <Avatar className="bg-black-100 dark:bg-white-100 dark:text-black-100">{user?.firstName.charAt(0)}</Avatar>
        </Dropdown>
      </div>
      <div className="mx-auto mt-2 box-border w-full pt-[48px]">{children}</div>
    </div>
  );
};

export default Main;
