import { z } from 'zod';

export const purchaseSchema = z.object({
  shop: z.string().min(3).trim().optional(),
  createdAt: z.string().optional()
});
export const productSchema = z.object({
  name: z.string(),
  box: z.number().int().min(1),
  quantity: z.number().int().min(1),
  price: z.number().positive(),
  code: z.string().optional()
});
export const payoutSchema = z.object({
  amount: z.number(),
  description: z.string(),
  createdAt: z.string()
});

export type PurchaseForm = z.infer<typeof purchaseSchema>;
export type PayoutForm = z.infer<typeof payoutSchema>;
export type ProductForm = z.infer<typeof productSchema>;
