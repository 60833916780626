import { TypedUseSelectorHook, useDispatch as useBaseDispatch, useSelector as useBaseSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';
import * as Types from './types';

const persistConfig: any = {
  key: `version-1`,
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type IStore = ReturnType<typeof store.getState>;
export type IDispatch = typeof store.dispatch;

const useDispatch = () => useBaseDispatch<IDispatch>();

const useSelector: TypedUseSelectorHook<Types.IState> = useBaseSelector;

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    })
});

const persistor = persistStore(store);

export * from './reducer';

export { persistor, store, useDispatch, useSelector };
