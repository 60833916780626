import z from 'zod';

export const userSchema = z.object({
  _id: z.string(),
  firstName: z.string().min(3).trim(),
  lastName: z.string().min(3).trim(),
  phone: z.string().min(3).trim()
});

export const shopSchema = z.object({
  _id: z.string(),
  title: z.string(),
  location: z.string(),
  phone: z.string(),
  number: z.string(),
  createdAt: z.string()
});

export const productSchema = z.object({
  _id: z.string(),
  name: z.string(),
  box: z.number(),
  quantity: z.number(),
  price: z.number(),
  code: z.string(),
  total: z.number()
});

export const payoutSchema = z.object({
  _id: z.string(),
  amount: z.number(),
  description: z.string(),
  updateReason: z.string(),
  createdAt: z.string()
});

export const purchaseSchema = z.object({
  _id: z.string(),
  total: z.number(),
  debt: z.number(),
  shop: shopSchema,
  products: z.array(productSchema),
  payouts: z.array(payoutSchema),
  createdAt: z.string()
});

export const metaSchema = z.object({
  limit: z.number().default(0),
  total: z.number().default(0),
  page: z.number().default(0),
  search: z.string().default('').optional()
});

export const errorSchema = z.object({
  id: z.string(),
  message: z.string()
});

export type User = z.infer<typeof userSchema>;
export type Shop = z.infer<typeof shopSchema>;
export type Seller = z.infer<typeof userSchema>;
export type Product = z.infer<typeof productSchema>;
export type Payout = z.infer<typeof payoutSchema>;
export type Purchase = z.infer<typeof purchaseSchema>;
export type Meta = z.infer<typeof metaSchema>;
export type Error = z.infer<typeof errorSchema>;
