import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import { useAuth } from 'modules/auth/hooks';

import { Main } from 'layouts';

import { Auth, Purchases, Shops } from 'pages';

import Protected from './protected';

const Routes = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Switch>
      <Route path="auth" element={<Protected allow={!isAuthenticated} to="/app" />}>
        <Route path="login" element={<Auth.Login />} />
        <Route path="register" element={<Auth.Register />} />
        <Route index path="*" element={<Navigate to="/auth/login" />} />
      </Route>

      <Route path="shops" element={<Protected Layout={Main} allow={isAuthenticated} to="/auth" />}>
        <Route index element={<Shops.Main />} />
        <Route path=":shopId" element={<Shops.Single />} />
        <Route path="*" element={<Navigate to="/shops" />} />
      </Route>

      <Route path="purchases" element={<Protected Layout={Main} allow={isAuthenticated} to="/auth" />}>
        <Route index element={<Purchases.Main />} />
        <Route path=":purchaseId" element={<Purchases.Single />} />
        <Route path="*" element={<Navigate to="/purchases" />} />
      </Route>

      <Route index path="*" element={<Navigate to={isAuthenticated ? '/shops' : '/auth/login'} />} />
    </Switch>
  );
};

export default Routes;
