import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { PURCHASE } from 'keys';
import { Product } from 'schema';

import { http } from 'services';

export const useDeletePayout = () => {
  const client = useQueryClient();
  const { purchaseId } = useParams();

  const mutate = useMutation<void, any, { payoutId: string }>({
    mutationFn: async ({ payoutId }) => {
      await http.request.delete<Product>(`/purchases/${purchaseId}/payout/${payoutId}`);
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: PURCHASE.list().queryKey });
      await client.invalidateQueries({ queryKey: PURCHASE.single({ purchaseId }).queryKey });
      message.success("Tolov muvaffaqiyatli o'chirildi");
    }
  });

  return mutate;
};
