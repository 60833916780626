import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, FloatButton, Input, Table, Tag, Typography } from 'antd';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { useShops } from 'modules/shops';
import { Delete } from 'modules/shops/form';

import { Icon, Modal } from 'components';

import { Add, Update } from '.';

const Main = () => {
  const navigate = useNavigate();
  const [{ page, limit, shopId, search }, setParams] = useQueryParams({ page: NumberParam, limit: NumberParam, shopId: StringParam, search: StringParam });
  const { shops, meta, isLoading, isFetching } = useShops({ page: page!, limit: limit!, search: search! });

  return (
    <div className="flex flex-col gap-2 px-2">
      <div className="flex items-center justify-between">
        <Typography title="Shops" className="text-xl font-bold">
          Do'konlar
        </Typography>
        <Input
          size="small"
          placeholder="Search"
          className="w-[200px]"
          value={search || ''}
          onChange={e => setParams({ search: e.target.value, page: undefined })}
        />
      </div>
      <Table
        size="small"
        rowKey="_id"
        bordered
        dataSource={shops}
        pagination={
          meta.total <= shops.length
            ? false
            : { total: meta.total, showTitle: true, pageSize: meta.limit, current: meta.page, size: 'default', position: ['bottomLeft'] }
        }
        loading={isLoading || isFetching}
        onChange={p => setParams({ page: p.current, limit: p.pageSize })}
        rowClassName="cursor-pointer"
        onRow={record => ({ onClick: () => navigate(`/shops/${record._id}`) })}
        columns={[
          {
            title: 'Nomi',
            dataIndex: 'title',
            width: 300
          },
          {
            title: 'Manzili',
            dataIndex: 'location',
            width: 300
          },
          {
            title: 'Raqami',
            dataIndex: 'number',
            width: 150
          },
          {
            title: 'Telefon raqam',
            dataIndex: 'phone',
            width: 250,
            render: (phone: string) => <Tag>{phone}</Tag>
          },
          {
            title: 'Harakat',
            dataIndex: '_id',
            className: 'flex justify-center',
            render: shopId => (
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      key: 'shop/edit',
                      className: 'flex items-center justify-start gap-1',
                      label: 'Edit',
                      icon: <Icon name="edit" className="h-4 w-4" />,
                      onClick: e => {
                        e.domEvent.stopPropagation();
                        setParams({ shopId });
                      }
                    },
                    { key: 'shop/delete', className: '!p-0', label: <Delete defaultValues={{ _id: shopId }} /> }
                  ]
                }}
              >
                <Button onClick={e => e.stopPropagation()} icon={<Icon name="verticalDots" />} />
              </Dropdown>
            )
          }
        ]}
      />
      <Modal key={shopId} centered onCancel={() => setParams({ shopId: undefined })} open={!!shopId}>
        {shopId === 'new' ? <Add /> : <Update shopId={shopId!} />}
      </Modal>

      <FloatButton onClick={() => setParams({ shopId: 'new' })} icon={<Icon name="plus" className="text-4xl" />} className="bottom-4 right-4" type="primary" />
    </div>
  );
};

export default Main;
