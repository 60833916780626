import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Collapse, Modal, Skeleton, Tag } from 'antd';
import { ArrayParam, BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { date, money } from 'utils';

import { usePurchase } from 'modules/purchases/hooks';

import { Icon } from 'components';

import Payouts from './payouts';
import Products from './products';
import Update from './update';

interface SingleProps {}

const Single: React.FC<SingleProps> = () => {
  const { purchaseId = '' } = useParams();
  const { purchase, isLoading } = usePurchase({ purchaseId });
  const [{ collapsed = [], isPurchaseUpdate }, setParams] = useQueryParams({
    collapsed: ArrayParam,
    productId: StringParam,
    payoutId: StringParam,
    isPurchaseUpdate: BooleanParam
  });

  if (isLoading)
    return (
      <>
        <Skeleton className="p-2" loading active />
        <Skeleton className="p-2" loading active />
        <Skeleton className="p-2" loading active />
      </>
    );

  const onNewProduct: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setParams({ productId: 'new' });
  };

  const onNewPayout: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    setParams({ payoutId: 'new' });
  };

  const items = [
    {
      key: 'shop',
      label: <span className="text-[14px] font-bold">Do'kon</span>,
      children: (
        <Card.Meta
          title={
            <div className="flex items-center gap-2">
              <span className="text-[14px]">{purchase?.shop.title}</span>
              <Tag className="m-0" color="orange">
                {purchase?.shop.location}
              </Tag>
              <Tag className="m-0" color="orange">
                {purchase?.shop.number}
              </Tag>
            </div>
          }
          description={
            <div className="flex gap-2">
              <span>{purchase?.shop.phone}</span>
            </div>
          }
        />
      ),
      show: true
    },
    {
      key: 'products',
      label: (
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2">
            <span className="text-[14px] font-bold">Mahsulotlar</span>
            <Tag color="purple">{purchase?.products.length} ta</Tag>
          </div>
          <Button onClick={onNewProduct} icon={<Icon name="plus" />} />
        </div>
      ),
      children: <Products items={purchase?.products!} />,
      show: true
    },
    {
      key: 'payouts',
      label: (
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2">
            <span className="text-[14px] font-bold">To'lovlar</span>
            <Tag color="purple">{purchase?.payouts.length} ta</Tag>
          </div>
          <Button onClick={onNewPayout} icon={<Icon name="plus" />} />
        </div>
      ),
      children: <Payouts payouts={purchase?.payouts!} />,
      show: !!purchase?.products.length
    }
  ];

  return (
    <>
      <div className="p-2">
        <div className="flex justify-between py-2">
          <div className="flex items-center gap-2">
            <span className="text-xl font-bold">Sotuv:</span>
            <Tag color="cyan">{purchase?.shop.title}</Tag>
            <Tag color="green">{money(purchase?.total!)}</Tag>
            <Tag color="red">{money(purchase?.debt!)}</Tag>
            <Tag color="blue">{date(purchase?.createdAt!)}</Tag>
          </div>
          <Button icon={<Icon name="edit" />} onClick={() => setParams({ isPurchaseUpdate: true })} />
        </div>
        <div className="flex flex-col gap-2">
          <Collapse
            className="bg-white-100"
            activeKey={collapsed as string[]}
            onChange={collapsed => setParams({ collapsed: collapsed as string[] })}
            items={items.filter(item => item.show)}
          />
        </div>
      </div>
      <Modal centered open={!!isPurchaseUpdate} onCancel={() => setParams({ isPurchaseUpdate: undefined })}>
        <Update />
      </Modal>
    </>
  );
};

export default Single;
