import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { Shop, shopSchema } from 'schema';

import { http } from 'services';

import { Icon } from 'components';

import { DeleteForm, deleteSchema } from '../schema';
import { SHOP } from '..';

interface DeleteProps {
  defaultValues?: Partial<DeleteForm>;
}

const Delete: React.FC<DeleteProps> = ({ defaultValues = {} }) => {
  const client = useQueryClient();
  const form = useForm<DeleteForm>({ resolver: zodResolver(deleteSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Shop, any, DeleteForm>({
    mutationFn: async ({ _id }) => {
      const { data } = await http.request.delete(`/shops/${_id}`);
      const shop = shopSchema.parse(data?.shop);

      return shop;
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: SHOP.list({}).queryKey! });
    }
  });

  const onSubmit = async (data: DeleteForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        form.handleSubmit(onSubmit)(e);
      }}
      className="flex items-center justify-between gap-1 border-none p-3"
      disabled={form.formState.isSubmitting}
      loading={form.formState.isSubmitting}
      type="primary"
      icon={<Icon name="trash" />}
      danger
    >
      Delete
    </Button>
  );
};

export default Delete;
