import React from 'react';
import { Button, Popconfirm, Table, Tag } from 'antd';
import { Product } from 'schema';
import { StringParam, useQueryParam } from 'use-query-params';
import { money } from 'utils';

import { useDeleteProduct } from 'modules/purchases/hooks';

import { Icon, Modal } from 'components';

import Add from './add';
import Update from './update';

interface ProductsProps {
  items: Product[];
}

const Products: React.FC<ProductsProps> = ({ items }) => {
  const [productId, setProductId] = useQueryParam('productId', StringParam);
  const deleteMutation = useDeleteProduct();

  return (
    <>
      <Table
        rowKey="_id"
        size="small"
        pagination={false}
        bordered
        dataSource={items}
        columns={[
          {
            title: 'Nomi',
            dataIndex: 'name',
            width: 400,
            render: (name, record) => (
              <div className="flex flex-col justify-center gap-1">
                <span className="text-xs font-bold">{name}</span>
                {record.code && (
                  <Tag className="w-max font-bold" color="blue">
                    {record.code}
                  </Tag>
                )}
              </div>
            )
          },
          {
            title: 'Qutida',
            dataIndex: 'box',
            width: 100
          },
          {
            title: 'Soni',
            dataIndex: 'quantity',
            width: 100
          },
          {
            title: 'Narxi',
            dataIndex: 'price',
            render: price => (
              <Tag color="geekblue" className="text-xs font-bold">
                {money(price)}
              </Tag>
            )
          },
          {
            title: 'Umumiy',
            dataIndex: 'total',
            render: total => (
              <Tag color="geekblue" className="text-xs font-bold">
                {money(total)}
              </Tag>
            )
          },
          {
            title: 'Amallar',
            dataIndex: '_id',
            render: productId => (
              <Button.Group>
                <Button onClick={() => setProductId(productId)} icon={<Icon name="edit" />} />
                <Popconfirm title="Rosdan ham o'chirmoqchimisz?" okText="Ha" cancelText="Bekor qilish" onConfirm={() => deleteMutation.mutate({ productId })}>
                  <Button loading={deleteMutation.isPending} danger type="primary" icon={<Icon name="trash" />} />
                </Popconfirm>
              </Button.Group>
            )
          }
        ]}
      />

      <Modal onCancel={() => setProductId(undefined)} key={productId} open={!!productId} centered style={{ minWidth: 700 }}>
        {productId === 'new' ? <Add /> : <Update />}
      </Modal>
    </>
  );
};

export default Products;
