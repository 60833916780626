import { useQueryClient } from '@tanstack/react-query';
import { Meta, Shop, shopSchema } from 'schema';

import { useQuery } from 'hooks';

import { http } from 'services';

import { SHOP } from '../../../keys';

export type IQuery = {
  shops: Shop[];
  meta: Meta;
};

interface Params extends Omit<Meta, 'total'> {
  enabled?: boolean;
}

export const useShops = ({ enabled, ...params }: Params) => {
  const client = useQueryClient();

  const initialData: IQuery = { shops: [], meta: { limit: 0, total: 0, page: 0 } };

  const { data = initialData, ...args } = useQuery<IQuery, string, IQuery>({
    queryKey: SHOP.list(params).queryKey,
    queryFn: async () => {
      const { data } = await http.request.get<IQuery>('/shops', { params });

      const shops = (data?.shops || []).map(shop => shopSchema.parse(shop));

      for (const shop of shops) client.setQueryData(SHOP.single({ shopId: shop._id }).queryKey, { shop });

      return data;
    },
    staleTime: 60000,
    enabled
  });

  return { ...data, ...args };
};
