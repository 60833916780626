import { baseURL, tokenKEY } from 'config';

import { store } from 'store';

import { http } from 'services';

http.init({
  configFn: async () => {
    const token = store.getState().auth.accessToken;

    return { baseURL, headers: { ...(token ? { [tokenKEY]: token } : {}) } };
  }
});
