import React from 'react';
import { AUTH } from 'keys';
import { User } from 'schema';

import { useQuery } from 'hooks';

import { login, logout, useDispatch } from 'store';

import { http } from 'services';

import { useAuth } from 'modules/auth';

import { Loader } from 'components';

interface AuthProps {
  children: React.ReactNode;
}

const Auth = ({ children }: AuthProps) => {
  const dispatch = useDispatch();
  const { accessToken, isFetched } = useAuth();

  useQuery<User, any>({
    queryKey: AUTH.user({ accessToken }).queryKey,
    queryFn: async () => {
      const { data } = await http.request.get<{ user: User }>('/auth/me');

      return data.user;
    },
    onSuccess: user => dispatch(login({ user })),
    onError: () => dispatch(logout()),
    enabled: !!accessToken,
    retry: false
  });

  return isFetched ? <>{children}</> : <Loader />;
};

export default Auth;
