import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Meta } from 'schema';

export const SHOP = createQueryKeys('shop', {
  list: (filter: Partial<Meta & { search: string }>) => [filter],
  single: (filter?: { shopId: string }) => [filter]
});

export const PURCHASE = createQueryKeys('purchase', {
  list: (filter?: Partial<Meta>) => [filter],
  single: (filter?: { purchaseId?: string }) => [filter]
});

export const AUTH = createQueryKeys('auth', {
  user: (filter: { accessToken?: string }) => [filter]
});
