import { shopSchema } from 'schema';
import z from 'zod';

export const updateSchema = shopSchema.omit({ createdAt: true });

export const addSchema = shopSchema.omit({ _id: true, createdAt: true });

export const deleteSchema = updateSchema.pick({ _id: true });

export type AddForm = z.infer<typeof addSchema>;
export type UpdateForm = z.infer<typeof updateSchema>;
export type DeleteForm = z.infer<typeof deleteSchema>;
