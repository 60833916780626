import React from 'react';
import { Button, Popconfirm, Table, Tag } from 'antd';
import { Payout } from 'schema';
import { StringParam, useQueryParam } from 'use-query-params';
import { date, money } from 'utils';

import { useDeletePayout } from 'modules/purchases/hooks';

import { Icon, Modal } from 'components';

import Add from './add';
import Update from './update';

interface PayoutsProps {
  payouts: Payout[];
}

const Payouts: React.FC<PayoutsProps> = ({ payouts }) => {
  const [payoutId, setPayoutId] = useQueryParam('payoutId', StringParam);
  const deleteMutation = useDeletePayout();

  return (
    <>
      <Table
        rowKey="_id"
        size="small"
        pagination={false}
        bordered
        dataSource={payouts}
        columns={[
          {
            title: 'Miqdori',
            dataIndex: 'amount',
            width: 200,
            render: amount => (
              <div className="flex flex-col justify-center gap-1">
                <Tag className="w-max font-bold" color="green">
                  {money(amount)}
                </Tag>
              </div>
            )
          },
          // {
          //   title: 'Qolgan',
          //   dataIndex: 'amount',
          //   width: 200,
          //   render: () => (
          //     <div className="flex flex-col justify-center gap-1">
          //       <Tag className="w-max font-bold" color="green">
          //         -
          //       </Tag>
          //     </div>
          //   )
          // },
          {
            title: 'Tolov sanasi',
            dataIndex: 'createdAt',
            render: createdAt => date(createdAt)
          },
          {
            title: 'Izoh',
            dataIndex: 'description',
            width: 300
          },
          {
            title: 'Amallar',
            dataIndex: '_id',
            render: payoutId => (
              <Button.Group>
                <Button onClick={() => setPayoutId(payoutId)} icon={<Icon name="edit" />} />
                <Popconfirm title="Rosdan ham o'chirmoqchimisz?" okText="Ha" cancelText="Bekor qilish" onConfirm={() => deleteMutation.mutate({ payoutId })}>
                  <Button loading={deleteMutation.isPending} danger type="primary" icon={<Icon name="trash" />} />
                </Popconfirm>
              </Button.Group>
            )
          }
        ]}
      />

      <Modal onCancel={() => setPayoutId(undefined)} key={payoutId} open={!!payoutId} centered style={{ minWidth: 700 }}>
        {payoutId === 'new' ? <Add /> : <Update />}
      </Modal>
    </>
  );
};

export default Payouts;
