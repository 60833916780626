import { Control, Controller } from 'react-hook-form';
import BaseInput, { InputProps as BaseInputProps } from 'antd/lib/input';

interface InputProps extends BaseInputProps {
  error?: string;
  type?: 'email' | 'text' | 'number';
  control: Control<any>;
  name: string;
  loading?: boolean;
}

const Input: React.FC<InputProps> = ({ error, name, control, loading = false, ...args }: InputProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <div className="flex flex-col gap-1">
        <label htmlFor={name}>{args.placeholder}</label>
        <BaseInput {...field} {...args} disabled={args.disabled || loading} id={name} />
        {error && <div className="text-sm !text-red-600">{error}</div>}
      </div>
    )}
  />
);

export default Input;
