import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { MaskedInput } from 'antd-mask-input';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';

interface PhoneInputProps extends Omit<MaskedInputProps, 'mask'> {
  error?: string;
  control: Control<any>;
  name: string;
}

const PhoneInput: FC<PhoneInputProps> = ({ error, name, control, ...args }: PhoneInputProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <div className="flex flex-col gap-1">
        <label htmlFor={name}>{args.placeholder}</label>
        <MaskedInput mask="+998-00-000-00-00" {...field} {...args} />
        {error && <div className="text-sm text-red-700">{error}</div>}
      </div>
    )}
  />
);

export default PhoneInput;
