import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import pick from 'lodash/pick';
import { StringParam, useQueryParam } from 'use-query-params';

import { Form } from 'modules/purchases';
import { useProduct } from 'modules/purchases/hooks';

import { Input } from 'components';

const Update: React.FC = () => {
  const { purchaseId } = useParams();
  const [productId, setProductId] = useQueryParam('productId', StringParam);
  const { item, isLoading } = useProduct({ purchaseId: purchaseId!, productId: productId! });

  if (isLoading)
    return (
      <div className="p-2">
        <Skeleton active loading />
        <Skeleton active loading />
        <Skeleton active loading />
      </div>
    );

  return (
    <div className="bg-white w-full rounded-lg shadow">
      <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
        <h1 className="m-0 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Mahsulotni tahrirlash</h1>
        <Form.UpdateProduct onSuccess={() => setProductId(undefined)} defaultValues={pick(item!, ['name', 'box', 'quantity', 'code', 'price'])}>
          {({ control, formState: { errors, isSubmitting } }) => (
            <div className="flex flex-col gap-3">
              <Input size="large" control={control} name="name" placeholder="Nomi" error={errors.name?.message} />
              <Input size="large" control={control} type="number" name="box" placeholder="Qutida" error={errors?.box?.message} />
              <Input size="large" control={control} type="number" name="quantity" placeholder="Miqdor" error={errors.quantity?.message} />
              <Input size="large" control={control} type="number" name="price" placeholder="Narx" error={errors.price?.message} />
              <Input size="large" control={control} name="code" placeholder="Shtrix kod" error={errors.code?.message} />
              <Button htmlType="submit" className="text-dark mx-auto" loading={isSubmitting} size="large">
                Saqlash
              </Button>
            </div>
          )}
        </Form.UpdateProduct>
      </div>
    </div>
  );
};

export default Update;
