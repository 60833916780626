import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { Form } from 'modules/auth';

import { Icon, Logo, Password, PhoneInput } from 'components';

const Login = () => (
  <section className="bg-white-100">
    <div className="flex h-screen flex-col items-center justify-center">
      <Logo className="mb-6" />

      <div className="bg-white relative w-full max-w-md rounded-lg shadow">
        <div className="space-y-4 p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Kirish</h1>
          <Form.Login>
            {({ control, formState: { errors, isSubmitting } }) => (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col justify-center gap-3">
                  <PhoneInput
                    control={control}
                    size="large"
                    prefix={<Icon name="phone" />}
                    placeholder="Telefon raqam"
                    error={errors.phone?.message}
                    name="phone"
                  />
                  <Password control={control} size="large" prefix={<Icon name="lock" />} placeholder="Parol" error={errors.password?.message} name="password" />
                </div>

                {/* <Link className="text-sm text-gray-900 hover:underline" to="/auth/forget">
                  Forget Password
                </Link> */}

                <Button htmlType="submit" className="text-dark mx-auto" loading={isSubmitting}>
                  Kirish
                </Button>

                <Link className="absolute bottom-3 right-8 text-xs text-gray-900 hover:underline" to="/auth/register">
                  Ro'yxatdan o'tish
                </Link>
              </div>
            )}
          </Form.Login>
        </div>
      </div>
    </div>
  </section>
);

export default Login;
