import 'bootstrap';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider, message } from 'antd';
import { AxiosError } from 'axios';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from 'routes';
import { errorSchema } from 'schema';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter as adapter } from 'use-query-params/adapters/react-router-6';

import { persistor, store } from 'store';

import { Auth } from 'modules/auth/containers';

import 'assets/styles/tailwind.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);

export const client = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: 1 },
    mutations: {
      onError(err, variables, context) {
        if (err instanceof AxiosError) {
          const error = errorSchema.parse(err.response?.data);

          message.error(error.message);
        }

        console.log('err = ', err);
      }
    }
  }
});

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={client}>
          <QueryParamProvider adapter={adapter}>
            <ConfigProvider
              theme={{
                token: { borderRadius: 6, colorPrimary: 'black' },
                components: { Input: { activeShadow: 'none' } }
              }}
            >
              <Auth>
                <Routes />
                <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
              </Auth>
            </ConfigProvider>
          </QueryParamProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

/**
 * -------- Tasks --------
 * 1. Implement Purchase Actions from single shop page
 *     🌀 implement purchase list
 *     🌀 implement purchase update logic
 *     🌀 implement navigate from one row onClicked
 * 2. Implement number format input
 *     🌀 implement amount
 *     🌀 implement price
 * 3. Implement searchable with backend sections
 *     🌀 products
 *     🌀 purchases
 *     🌀 shops
 *
 *
 */
