import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Skeleton } from 'antd';

import { Form, usePurchase } from 'modules/purchases';
import { useShops } from 'modules/shops';

import { DatePicker, Select } from 'components';

const Update: React.FC = () => {
  const { purchaseId } = useParams();
  const { shops, isLoading } = useShops({ page: 1, limit: 1000 });
  const { purchase, isLoading: singleIsLoading } = usePurchase({ purchaseId: purchaseId! });
  const navigate = useNavigate();

  if (isLoading || singleIsLoading)
    return (
      <div className="p-2">
        <Skeleton active loading />
        <Skeleton active loading />
      </div>
    );

  return (
    <div className="bg-white w-full rounded-lg shadow">
      <div className="flex flex-col gap-2 p-6">
        <h1 className="m-0 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Sotuv tahrirlash</h1>
        <Form.UpdatePurchase
          defaultValues={{ shop: purchase?.shop._id, createdAt: purchase?.createdAt }}
          onSuccess={purchase => navigate(`/app/purchases/${purchase._id}`)}
        >
          {({ control, formState: { errors, isSubmitting } }) => (
            <div className="flex flex-col gap-3">
              <Select
                allowClear
                className="w-full"
                searchableKeys={['title', 'phone']}
                size="large"
                control={control}
                options={shops.map(record => ({ label: record.title, value: record._id, record }))}
                showSearch
                autoClearSearchValue
                placeholder="Do'kon"
                error={errors?.shop?.message}
                name="shop"
              />

              <DatePicker placeholder="Sotuv sanasi" control={control} name="createdAt" />

              <Button htmlType="submit" className="text-dark mx-auto w-max self-end" loading={isSubmitting}>
                Saqlash
              </Button>
            </div>
          )}
        </Form.UpdatePurchase>
      </div>
    </div>
  );
};

export default Update;
