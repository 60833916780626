import { Shop } from 'schema';

import { useQuery } from 'hooks';

import { http } from 'services';

import { SHOP } from '../../../keys';

interface IQuery {
  shop: Shop;
}

interface Params {
  shopId: string;
}

export const useShop = ({ shopId }: Params) => {
  const { data, ...args } = useQuery<IQuery, string, IQuery>({
    queryKey: SHOP.single({ shopId }).queryKey,
    queryFn: async () => {
      const { data } = await http.request.get<{ shop: Shop }>(`/shops/${shopId}`);

      return { shop: data.shop };
    },
    staleTime: 60000,
    enabled: !!shopId
  });

  return { ...data, ...args };
};
