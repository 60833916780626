import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Product } from 'schema';

import { http } from 'services';

import { ProductForm, productSchema } from '../schema';

interface AddProductProps {
  defaultValues?: Partial<ProductForm>;
  children: (props: UseFormReturn<ProductForm>) => React.ReactNode;
  onSuccess?: (data: Product) => void;
}

const AddProduct: React.FC<AddProductProps> = ({ children, defaultValues = {}, onSuccess }) => {
  const client = useQueryClient();
  const { purchaseId } = useParams();
  const form = useForm<ProductForm>({ resolver: zodResolver(productSchema as any), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Product, any, ProductForm>({
    mutationFn: async values => {
      const { data: item } = await http.request.post<Product>(`/purchases/${purchaseId}/product`, values);

      return item;
    },
    onSuccess: async product => {
      client.invalidateQueries({ queryKey: ['PURCHASE', 'LIST'] });
      client.invalidateQueries({ queryKey: ['PURCHASE', purchaseId] });

      message.success('Mahsulot muvaffaqiyatli yaratildi');
      form.reset();
      onSuccess!(product);
    }
  });

  const onSubmit = async (data: ProductForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default AddProduct;
