import React from 'react';
import { cx } from 'utils';

interface LogoProps {
  className?: string;
  title?: string;
}

const Logo: React.FC<LogoProps> = ({ title = 'Jonatma', className }) => (
  <div className={cx('flex items-center gap-2 text-2xl', className)}>
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={33} viewBox="0 0 32 33" fill="none">
      <link type="text/css" rel="stylesheet" id="dark-mode-custom-link" />
      <link type="text/css" rel="stylesheet" id="dark-mode-general-link" />
      <style lang="en" type="text/css" id="dark-mode-custom-style" />
      <style lang="en" type="text/css" id="dark-mode-native-style" />
      <style lang="en" type="text/css" id="dark-mode-native-sheet" />
      <path
        d="M24.5199 13.4434C24.4458 13.9538 24.1092 14.6473 23.723 15.2502C23.2358 16.0109 22.4627 16.5438 21.5779 16.7288L17.6802 17.5439C17.0537 17.6749 16.4857 18.0027 16.0592 18.4795L13.5035 21.3361C13.0232 21.873 12.6875 21.745 12.6875 21.0249C12.6817 21.0516 11.4191 24.3077 14.7651 26.2376C16.0507 26.9791 17.9013 26.7127 19.1869 25.9712L25.9988 22.042C28.5463 20.5726 30.345 18.0868 30.9428 15.2094C30.9665 15.0955 30.9848 14.9813 31.0045 14.8671L24.5199 13.4434Z"
        fill="url(#paint0_linear_13207_83133)"
      />
      <path
        d="M22.7528 9.51725C24.0384 10.2588 24.5637 11.3628 24.5637 12.8459C24.5637 13.0472 24.5479 13.2461 24.5194 13.442L27.2641 14.621L31.004 14.8658C31.4829 12.0943 30.5444 9.24153 28.862 6.97396C27.5959 5.26751 25.9667 3.78665 24.0081 2.65689C22.417 1.73917 20.7636 1.13452 19.1025 0.802734L17.2361 3.21974L16.6465 5.9951L22.7528 9.51725Z"
        fill="url(#paint1_linear_13207_83133)"
      />
      <path
        d="M0.783353 11.5518C0.782654 11.554 0.784588 11.5546 0.785316 11.5524C0.929339 11.1205 1.10993 10.6385 1.33539 10.1237C2.5131 7.4343 4.78262 5.64062 7.57467 4.72608C10.3667 3.81156 13.4153 4.13212 15.9599 5.59988L16.6463 5.99578L19.1023 0.803412C11.2907 -0.756764 3.30703 3.83253 0.793284 11.5217C0.792083 11.5254 0.787713 11.5382 0.783353 11.5518Z"
        fill="url(#paint2_linear_13207_83133)"
      />
      <path
        d="M18.9201 25.9714C17.6345 26.7129 16.0506 26.7129 14.765 25.9714C14.5904 25.8707 14.4259 25.7575 14.2703 25.635L12.0093 27.1895L10.0605 30.3386C12.2236 32.1387 15.0324 32.7174 17.8392 32.3955C19.9515 32.1533 22.0498 31.4841 24.0084 30.3544C25.5995 29.4367 26.9503 28.3085 28.0685 27.0371L26.9065 24.2138L25.0265 22.4492L18.9201 25.9714Z"
        fill="url(#paint3_linear_13207_83133)"
      />
      <path
        d="M14.2694 25.6346C13.2789 24.8544 12.6864 23.6577 12.6864 22.3759V22.2481V11.5729C12.6864 10.9692 12.8643 10.8666 13.3876 11.1685C12.5816 10.7035 10.7195 9.10749 8.42044 10.4336C7.13484 11.1752 6.07617 12.812 6.07617 14.2951V22.1534C6.07617 25.0922 7.59833 28.1578 9.79375 30.1138C9.88059 30.1911 9.97049 30.2641 10.0596 30.3382L14.2694 25.6346Z"
        fill="url(#paint4_linear_13207_83133)"
      />
      <path
        d="M27.9097 5.81328C27.9081 5.81158 27.9066 5.81295 27.9081 5.81466C28.2106 6.15526 28.538 6.55248 28.8716 7.00497C30.614 9.36862 31.2651 12.3623 30.6618 15.2353C30.0586 18.1082 28.2564 20.5855 25.7118 22.0533L25.0254 22.4492L28.0674 27.0371C33.3256 21.0586 33.3392 11.8564 27.9308 5.83692C27.9282 5.83404 27.9193 5.82385 27.9097 5.81328Z"
        fill="url(#paint5_linear_13207_83133)"
      />
      <path
        d="M6.34355 14.2958C6.34354 12.8127 7.13551 11.4423 8.42113 10.7007C8.59565 10.6001 8.77601 10.5143 8.96002 10.441L8.74304 7.70749L7.21861 4.58008C4.57671 5.55151 2.4397 7.55912 1.31528 10.1485C0.469097 12.0972 9.792e-06 14.2472 0 16.5067C0 18.3422 0.302549 20.075 0.845532 21.6782L3.87391 22.0845L6.34355 21.3401V14.2958V14.2958Z"
        fill="url(#paint6_linear_13207_83133)"
      />
      <path
        d="M8.96016 10.44C10.1317 9.97312 11.4653 10.0589 12.5764 10.6998L12.6872 10.7637L21.5826 15.8946C22.2066 16.2545 22.1499 16.6087 21.4446 16.7561L21.9578 16.6488C22.633 16.5077 23.2499 16.1626 23.7217 15.6597C24.5328 14.7951 24.8306 13.752 24.8306 12.8467C24.8306 11.3637 24.0386 9.99323 22.753 9.25169L15.9411 5.32258C13.3936 3.85316 10.3395 3.53983 7.54622 4.46132C7.43572 4.49776 7.32756 4.53909 7.21875 4.5791L8.96016 10.44Z"
        fill="url(#paint7_linear_13207_83133)"
      />
      <path
        d="M19.3224 32.1533C19.3246 32.1528 19.3242 32.1508 19.3219 32.1513C18.8755 32.2427 18.3674 32.3274 17.8084 32.3897C14.8882 32.7155 11.9677 31.7819 9.77888 29.8235C7.59011 27.8651 6.34372 25.0672 6.34372 22.1317L6.34371 21.3398L0.845703 21.6779C3.39905 29.2166 11.3691 33.8294 19.2913 32.1597C19.2951 32.159 19.3084 32.1563 19.3224 32.1533Z"
        fill="url(#paint8_linear_13207_83133)"
      />
      <defs>
        <linearGradient id="paint0_linear_13207_83133" x1="20.0604" y1="24.2706" x2="23.2079" y2="13.3075" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1724C9" />
          <stop offset={1} stopColor="#1C64F2" />
        </linearGradient>
        <linearGradient id="paint1_linear_13207_83133" x1="27.3093" y1="10.8996" x2="19.0297" y2="2.64913" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1C64F2" />
          <stop offset={1} stopColor="#0092FF" />
        </linearGradient>
        <linearGradient id="paint2_linear_13207_83133" x1="16.1642" y1="5.52115" x2="3.67407" y2="6.3104" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0092FF" />
          <stop offset={1} stopColor="#45B2FF" />
        </linearGradient>
        <linearGradient id="paint3_linear_13207_83133" x1="15.32" y1="29.1636" x2="26.5369" y2="26.1368" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1C64F2" />
          <stop offset={1} stopColor="#0092FF" />
        </linearGradient>
        <linearGradient id="paint4_linear_13207_83133" x1="7.26808" y1="16.1832" x2="15.2317" y2="24.4352" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1724C9" />
          <stop offset={1} stopColor="#1C64F2" />
        </linearGradient>
        <linearGradient id="paint5_linear_13207_83133" x1="25.4497" y1="22.1366" x2="31.0061" y2="10.9354" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0092FF" />
          <stop offset={1} stopColor="#45B2FF" />
        </linearGradient>
        <linearGradient id="paint6_linear_13207_83133" x1="5.36387" y1="9.63214" x2="2.39054" y2="20.8077" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1C64F2" />
          <stop offset={1} stopColor="#0092FF" />
        </linearGradient>
        <linearGradient id="paint7_linear_13207_83133" x1="20.5432" y1="9.09961" x2="9.6778" y2="11.8049" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1724C9" />
          <stop offset={1} stopColor="#1C64F2" />
        </linearGradient>
        <linearGradient id="paint8_linear_13207_83133" x1="6.40691" y1="21.8576" x2="13.3327" y2="32.2755" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0092FF" />
          <stop offset={1} stopColor="#45B2FF" />
        </linearGradient>
      </defs>
    </svg>
    <b className="no-underline">{title}</b>
  </div>
);

export default Logo;
