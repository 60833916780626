import { useNavigate } from 'react-router-dom';
import { Table, Tag } from 'antd';
import { Payout, Product, Shop } from 'schema';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { date, money } from 'utils';

import { usePurchases } from 'modules/purchases';

import Icon from 'components/icon';

import Add from './add';

const Main = () => {
  const [{ page, limit }, setParams] = useQueryParams({ page: NumberParam, limit: NumberParam, purchaseId: StringParam });

  const { purchases, meta, isLoading, isFetching } = usePurchases({ page: page!, limit: limit! });
  const navigate = useNavigate();

  return (
    <div className="h-screen overflow-scroll">
      <Table
        bordered
        size="small"
        rowKey="_id"
        pagination={
          meta.total <= purchases.length
            ? false
            : { total: meta.total, showTitle: true, pageSize: meta.limit, current: meta.page, size: 'default', position: ['bottomLeft'] }
        }
        onChange={p => setParams({ page: p.current, limit: p.pageSize })}
        loading={isLoading || isFetching}
        rowClassName="cursor-pointer"
        dataSource={purchases}
        columns={[
          {
            title: (
              <div className="grid cursor-pointer place-items-center" onClick={() => setParams({ purchaseId: 'new' })}>
                <Icon name="plus" />
              </div>
            ),
            width: 30,
            render: (value, record, idx) => <b className="grid place-items-center">{idx + 1}</b>
          },
          {
            title: "Do'kon",
            dataIndex: 'shop',
            width: 200,
            render: (shop: Shop) => (
              <div className="flex flex-col gap-1">
                <span>{shop.title}</span>
                <Tag className="mx-0 grid w-max place-items-center">{shop.phone}</Tag>
              </div>
            )
          },
          {
            title: 'Sotuv Sanasi',
            dataIndex: 'createdAt',
            width: 150,
            render: (createdAt: string) => date(createdAt)
          },
          {
            title: 'Umumiy',
            dataIndex: 'total',
            width: 150,
            render: (total: number) => (
              <Tag color="geekblue" className="text-xs">
                {money(total)}
              </Tag>
            )
          },
          {
            title: 'Qarz',
            dataIndex: 'debt',
            width: 150,
            render: (debt: number) => (
              <Tag color="red" className="text-xs">
                {money(debt)}
              </Tag>
            )
          },
          {
            title: "To'lovlar",
            dataIndex: 'payouts',
            width: 100,
            render: (payouts: Payout[]) => (
              <Tag className="mx-0 grid w-max place-items-center" color="red">
                {payouts.length} ta
              </Tag>
            )
          },
          {
            title: 'Mahsulotlar',
            dataIndex: 'products',
            width: 100,
            render: (products: Product[]) => (
              <Tag className="mx-0 grid w-max place-items-center" color="red">
                {products.length} ta
              </Tag>
            )
          }
        ]}
        onRow={({ _id }) => ({ onClick: () => navigate(`/app/purchases/${_id}`) })}
      />
      <Add />
    </div>
  );
};

export default Main;
