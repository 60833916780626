import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Product } from 'schema';
import { useQueryParam } from 'use-query-params';

import { http } from 'services';

import { ProductForm, productSchema } from '../schema';

interface UpdateProductProps {
  defaultValues?: Partial<ProductForm>;
  children: (props: UseFormReturn<ProductForm>) => React.ReactNode;
  onSuccess?: (data: Product) => void;
}

const UpdateProduct: React.FC<UpdateProductProps> = ({ children, defaultValues = {}, onSuccess }) => {
  const { purchaseId } = useParams();
  const [productId] = useQueryParam('productId');
  const client = useQueryClient();
  const form = useForm<ProductForm>({ resolver: zodResolver(productSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Product, any, ProductForm>({
    mutationFn: async values => {
      const { data: item } = await http.request.put<Product>(`/purchases/${purchaseId}/product/${productId}`, values);

      client.setQueryData(['PURCHASE', 'PRODUCT', purchaseId, productId], { item });
      return item;
    },
    onSuccess: async product => {
      client.invalidateQueries({ queryKey: ['PURCHASE', 'LIST'] });
      client.invalidateQueries({ queryKey: ['PURCHASE', purchaseId] });

      message.success('Mahsulot muvaffaqiyatli tahrirlandi');
      form.reset();
      onSuccess!(product);
    }
  });

  const onSubmit = async (data: ProductForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default UpdateProduct;
