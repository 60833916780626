import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Product } from 'schema';

import { http } from 'services';

export const useDeleteProduct = () => {
  const client = useQueryClient();
  const { purchaseId } = useParams();

  const mutate = useMutation<void, any, { productId: string }>({
    mutationFn: async ({ productId }) => {
      await http.request.delete<Product>(`/purchases/${purchaseId}/product/${productId}`);
    },
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: ['PURCHASE', 'LIST'] });
      await client.invalidateQueries({ queryKey: ['PURCHASE', purchaseId] });
      message.success("Mahsulot muvaffaqiyatli o'chirildi");
    }
  });

  return mutate;
};
