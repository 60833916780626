import { Control, Controller } from 'react-hook-form';
import BaseSelect, { SelectProps as BaseSelectProps } from 'antd/lib/select';
import { BaseOptionType as RcBaseOptionType, DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';

interface BaseOptionType<T> extends RcBaseOptionType {
  record: T;
}

interface SelectProps<TRecord> extends BaseSelectProps<TRecord, BaseOptionType<TRecord>> {
  error?: string;
  control: Control<any>;
  name: string;
  searchableKeys: Array<keyof TRecord>;
}

export default function Select<TRecord = any>({ error, name, control, searchableKeys, ...args }: SelectProps<TRecord>) {
  const onSearch: FilterFunc<BaseOptionType<TRecord> | DefaultOptionType> = (input, option) => {
    if (!option) return false;

    for (const key of searchableKeys) {
      const value = option.record[key];

      if (value!.toString().search(new RegExp(input, 'i')) !== -1) return true;
    }

    return false;
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className="flex flex-col gap-1">
          <label htmlFor={name}>{args.placeholder}</label>
          <BaseSelect filterOption={onSearch} id={name} autoClearSearchValue showSearch {...field} {...args} />
          {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
        </div>
      )}
    />
  );
}
