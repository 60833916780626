import { Payout } from 'schema';

import { useQuery } from 'hooks';

import { http } from 'services';

interface IQuery {
  item: Payout;
}

interface Params {
  purchaseId: string;
  payoutId: string;
}

export const usePayout = ({ purchaseId, payoutId }: Params) => {
  const { data, ...args } = useQuery<IQuery, string, IQuery>({
    queryKey: ['PURCHASE', 'PAYOUT', purchaseId, payoutId],
    queryFn: async () => {
      const { data: item } = await http.request.get<Payout>(`/purchases/${purchaseId}/payout/${payoutId}`);

      return { item };
    },
    enabled: !!(purchaseId && payoutId),
    staleTime: 60000
  });

  return { ...data, ...args };
};
