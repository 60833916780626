import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Shop, shopSchema } from 'schema';
import { StringParam, useQueryParam } from 'use-query-params';

import { http } from 'services';

import { AddForm, addSchema } from '../schema';
import { SHOP } from '..';

interface AddProps {
  defaultValues?: Partial<AddForm>;
  children: (props: UseFormReturn<AddForm>) => React.ReactNode;
}

const Add: React.FC<AddProps> = ({ children, defaultValues = {} }) => {
  const client = useQueryClient();
  const form = useForm<AddForm>({ resolver: zodResolver(addSchema), defaultValues: { ...defaultValues } });
  const setShopId = useQueryParam('shopId', StringParam)[1];

  const mutation = useMutation<Shop, any, AddForm>({
    mutationFn: async values => {
      const { data } = await http.request.post('/shops', values);
      const shop = shopSchema.parse(data?.shop);

      return shop;
    },
    onSuccess: async () => {
      message.success("Do'kon muvaffaqiyatli yaratildi");
      await client.invalidateQueries({ queryKey: SHOP.list({}).queryKey });
      form.reset();
      setShopId(undefined);
    }
  });

  const onSubmit = async (data: AddForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default Add;
