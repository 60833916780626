import { PURCHASE } from 'keys';
import { Purchase } from 'schema';

import { useQuery } from 'hooks';

import { http } from 'services';

interface IQuery {
  purchase: Purchase;
}

interface Params {
  purchaseId: string;
}

export const usePurchase = ({ purchaseId }: Params) => {
  const { data, ...args } = useQuery<IQuery, string, IQuery>({
    queryKey: PURCHASE.single({ purchaseId }).queryKey,
    queryFn: async () => {
      const { data } = await http.request.get<IQuery>(`/purchases/${purchaseId}`);

      return { purchase: data.purchase };
    },
    staleTime: 60000,
    enabled: !!purchaseId
  });

  return { ...data, ...args };
};
