import React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Purchase } from 'schema';

import { http } from 'services';

import { PurchaseForm, purchaseSchema } from '../schema';

interface AddPurchaseProps {
  defaultValues?: Partial<PurchaseForm>;
  children: (props: UseFormReturn<PurchaseForm>) => React.ReactNode;
  onSuccess?: (data: Purchase) => void;
}

const AddPurchase: React.FC<AddPurchaseProps> = ({ children, defaultValues = {}, onSuccess }) => {
  const client = useQueryClient();
  const form = useForm<PurchaseForm>({ resolver: zodResolver(purchaseSchema), defaultValues: { ...defaultValues } });

  const mutation = useMutation<Purchase, any, PurchaseForm>({
    mutationFn: async values => {
      const { data: item } = await http.request.post<Purchase>('/purchases', values);

      return item;
    },
    onSuccess: async purchase => {
      client.invalidateQueries({ queryKey: ['PURCHASE', 'LIST'] });
      message.success('Sotuv muvaffaqiyatli yaratildi');
      form.reset();
      onSuccess!(purchase);
    }
  });

  const onSubmit = async (data: PurchaseForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
      {children(form)}
    </form>
  );
};

export default AddPurchase;
