import React from 'react';
import { Button } from 'antd';
import { StringParam, useQueryParam } from 'use-query-params';

import { Form } from 'modules/purchases';

import { DatePicker, Input } from 'components';

const Add: React.FC = () => {
  const [payoutId, setPayoutId] = useQueryParam('payoutId', StringParam);

  if (payoutId !== 'new') return null;

  return (
    <div className="bg-white w-full rounded-lg shadow">
      <div className="space-y-4 p-6 sm:p-8 md:space-y-6">
        <h1 className="m-0 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">To'lov yaratish</h1>
        <Form.AddPayout onSuccess={() => setPayoutId(undefined)}>
          {({ control, formState: { errors, isSubmitting } }) => (
            <div className="flex flex-col gap-2">
              <Input size="large" type="number" control={control} name="amount" placeholder="Miqdor" error={errors.amount?.message} />
              <Input size="large" control={control} name="description" placeholder="Izoh" error={errors.description?.message} />
              <DatePicker control={control} name="createdAt" placeholder="To'lov sanasi" error={errors.createdAt?.message} />
              <Button htmlType="submit" className="text-dark mx-auto" loading={isSubmitting} size="large">
                Yaratish
              </Button>
            </div>
          )}
        </Form.AddPayout>
      </div>
    </div>
  );
};

export default Add;
