import { logout, useDispatch, useSelector } from 'store';

import { IStore } from '../slice';

interface IReturn extends IStore {
  isAuthenticated: boolean;
  methods: { logout(): void };
}

export const useAuth = (): IReturn => {
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const isFetched = useSelector(({ auth }) => auth.isFetched);
  const accessToken = useSelector(({ auth }) => auth.accessToken);

  const methods = {
    logout: () => dispatch(logout())
  };

  return { user, isFetched, isAuthenticated: !!user, accessToken, methods };
};

/**
 * ----- Backend -----
 * ✅ Update Purchase (products, status: CLOSED || CREATED || PROCESSING)
 * ----- Frontend -----
 * ✅ Update Purchase (products)
 * ✅ Create Purchase from shops page (products)
 *
 *
 *
 */
